<template>
  <div class="branche">
      <div class="branche-name">
        <h3>{{branche_name}}</h3>
      </div>
      <div class="actions">
          <img @click="handle_delete(branche_name)" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Ftrash-bin_375x375.png?alt=media&token=343ab5d5-380b-4c00-9c6d-ca5152b4673b" alt="">
          <img @click="update_branche(branche_name)" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fpencil%20(1)_375x375.png?alt=media&token=78a64ec1-c7b4-49d0-ba40-963c4db41bed" alt="">
      </div>    
  </div>
</template>

<script>
import {alert_confirm} from '../../../../Methods/Msgs'
import {projectFirestore} from '../../../../firebase/config'
import Swal from 'sweetalert2'
export default {
    props:['branche_name'],
    emits:['delete','modify'],
    setup(_,{emit}){
        const update_branche=async(uid)=>{
            Swal.fire({
                icon: 'question',
                title: 'עדכן שם סניף',
                input: 'text',
                confirmButtonText: 'עדכן',
                cancelButtonText: 'בטל',
                showCancelButton: true,
                denyButtonText: 'בטל עדכון',
                showDenyButton: true
            }).then(async res=>{
                if(res.isConfirmed && res.value){
                    await handle_modify_branche(uid,res.value)
                }
            })
        }
        const handle_delete = async(branche_name)=>{
            alert_confirm(`האם למחוק את סניף ${branche_name}`)
            .then(async(res)=>{
                if(res.isConfirmed){
                    await projectFirestore.collection('Shivuk')
                    .doc('Shivuk').collection('Branches').doc(branche_name)
                    .delete()
                    emit('delete',branche_name)
                }
            })

        }
        const handle_modify_branche = async(old_branche_name,new_branche_name)=>{
            await projectFirestore.collection('Shivuk')
                    .doc('Shivuk').collection('Branches').doc(old_branche_name)
                    .delete()
             await projectFirestore.collection('Shivuk')
                    .doc('Shivuk').collection('Branches').doc(new_branche_name)
                    .set({
                        name:new_branche_name
                    })
            emit('modify',{old_branche_name,new_branche_name})
        }
        return{handle_delete,handle_modify_branche,update_branche}
    }
}
</script>

<style scoped>
    .branche{
        background: #fff;
        width: 100%;
        height: 100%;
        -webkit-box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.15); 
        box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.15);
        border-radius: 10px;
        display: flex;
    }
    .branche-name{
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .actions{
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    img{
        cursor: pointer;
        width: 32px;
        height: 32px;
    }
</style>